.SongWriterEditModal {
    &-headerContent {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
    }

    #cancel-button {
        margin-right: 0.5rem;
    }

    .Pill {
        background-color: var(--gray-300);
        margin-right: 0.3rem;
    }

    &-title {
        display: flex;
        align-items: center;
    }

    &-compCount {
        text-align: right;
        position: absolute;
        right: 3rem;
        display: flex;
        font-size: larger;

        #controlled {
            margin-right: 1rem;
        }

        #count {
            font-weight: bold;
        }
    }

    &-basics-body {
        width: 100%;
        align-items: center;
        justify-content: center;
        border: 1px solid #e5e5e5;
        padding: 20px;
        border-radius: 10px;
    }

    &-half-size-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &-half-size {
        width: 49%;
    }

    .FullscreenModal-title {
        text-align: left;
    }

    .FullscreenModal-body-container {
        padding: 5rem;
    }

    &-editPublishingCompany-body {
        &-description {
            margin-bottom: 20px;

            &-update-label {
                label {
                    font-weight: lighter !important;
                    font-size: 0.9rem !important;
                    margin-left: 5px;
                }
            }
        }
        .form-check-label {
            font-weight: normal;
        }

        button {
            font-weight: bold;
            float: right;
        }

        .btn-cancel {
            margin-right: 0.5rem;
        }
    }

    &-associatedComps {
        margin-top: 3rem;

        &-section {
            display: flex;
        }

        &-controlled,
        &-nonControlled {
            padding: 1rem;
            border: 1px solid var(--gray-400);
            border-radius: 0.25rem;
            width: 50%;
        }

        &-controlled {
            margin-right: 2rem;
        }

        &-sectionHeader {
            .SuiteSection-header-title {
                font-size: large;
            }
        }

        &-selected {
            .SuiteSection-header-title {
                font-size: small;
            }
        }

        &-header {
            display: flex;
            align-items: center;

            h2 {
                width: 100%;
            }
        }

        .PublisherSearchDropdown {
            margin-bottom: 1rem;
            display: flex;
            justify-content: right;

            .SuiteSelect-input {
                width: 296px;
            }
        }

        &-emptyState {
            justify-items: center;
        }
    }

    .GridTable.empty {
        background: none;
    }

    #publisher {
        font-size: smaller;
    }

    .Alert {
        margin-top: 2.5rem;
        background-color: #f0f7ff; // @todo - we should be able to remove this with the v2 colour scheme
        border-color: #7abdff; // @todo - we should be able to remove this with the v2 colour scheme
    }
}

// @todo - we should be able to remove this styling when we switch to V2 colour scheme
.FormCheckbox {
    border: 1px solid var(--gray-400);
    border-radius: 0.25rem;
}

.SongWriterManager-pka-field-x {
    margin-top: 10px;
}

.PopoverContent {
    overflow-y: visible !important;
}
